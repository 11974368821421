@import url("https://fonts.googleapis.com/css2?family=Caveat&family=Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora&display=swap");

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Sora", sans-serif;
  font-display: swap;
  color: #06091d;
}

body,
html,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

// app

.app {
  margin: 0 auto;
  max-width: 900px;

  &__container {
    margin: 0 2rem;
  }

  &__navbar {
    color: #06091d;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0 6rem;
    width: 100%;
  }

  &__topic {
    margin-bottom: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem;
    padding-top: 1rem;
    width: 100%;
  }
}

// navbar

.navbar {
  &__logo {
    font-size: 36px;
    font-family: "Caveat";
    font-weight: bold;
  }

  &__links {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
  }

  &__link {
    font-size: 16px;
    color: #06091d;
    text-decoration: none;
    padding: 0px 5px;
  }

  &__link:hover {
    cursor: pointer;
    color: darkcyan;
    font-weight: bold;
  }
}

// mobile navbar

.mobile-navbar {
  position: relative; /* Set position to relative for positioning the dropdown */
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &__menu-icon {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    cursor: pointer;

    width: 25px;
    height: auto;
  }

  &__menu-dropdown {
    position: absolute; /* Position dropdown absolutely */
    display: flex; /* Display menu items in a column */
    flex-direction: column;
    top: 100%; /* Position it below the menu icon */
    background-color: white;
    z-index: 1; /* Ensure it's above other content */
    padding: 0 5px;
    border-radius: 10px;
  }
}

// topic

.topic {
  &__about-me {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: rgb(238, 238, 238);
    border-radius: 20px;
  }

  &__skills,
  &__projects,
  &__contact {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.topic-headline {
  font-size: 32px;
  margin-bottom: 30px;
  text-decoration: underline;
  font-weight: bold;
  text-underline-offset: 10px;
}

// about me

.about-me {
  &__avatar {
    border-radius: 50%;
    margin: 3rem 2rem;
    width: 40%;
  }

  &__description {
    margin: 0 50px;
    display: flex;
    flex-direction: column;
  }
}

.description {
  &__headline {
    font-size: 36px;
  }

  &__text {
    font-size: 20px;
    margin-top: 20px;
    line-height: 30px;
  }
}

// skills

.skills {
  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    justify-items: center;
  }

  &__list-item {
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.list-item {
  &__image {
    margin-bottom: 12px;
    width: auto;
    height: 60px;
  }
}

h5 {
  font-size: 14px;
  font-weight: lighter;
  margin: 0;
}

// projects

.projects {
  &__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

// project card

.project {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__title {
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__prev {
    object-fit: cover;
    width: 100%;
    height: 18rem;
    object-position: 50% 0;
    border-radius: 10px;
    border: 2px solid #f0f0f0;
  }

  &__menu {
    display: flex;
    margin-top: auto;
    width: 100%;
  }

  &__menu-link {
    text-decoration: none;
    color: #06091d;
    font-size: 14px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgb(238, 238, 238);
  }

  &__menu-link:not(:last-child) {
    margin-right: 10px;
  }

  &__menu-link:hover {
    background-color: darkcyan;
    border-radius: 10px;
    color: white;
  }
}

// contact

.contact {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__social-link {
    text-decoration: none;
    color: #3e4c59;
    font-size: 24px;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
}

.social-link {
  &__link {
    text-decoration: none;
    color: #3e4c59;
    font-size: 16px;
    padding: 0 10px;
  }

  &__logo {
    width: 25px;
    height: auto;
  }

  &__link:hover {
    cursor: pointer;
    color: darkcyan;
    font-weight: bold;
  }
}

// footer

.footer {
  &__text {
    font-size: 14px;
    color: #3e4c59;
  }

  &__emoji {
    width: 1em;
    height: 1em;
  }
}

.website_link {
  text-decoration: none;
  color: darkcyan;
}

.website_link:hover {
  text-decoration: underline;
}

// mobile

@media (max-width: 768px) {
  .app {
    &__navbar {
      margin: 2rem 0;
    }

    &__topic {
      margin-bottom: 4rem;
    }
  }

  .topic {
    &__about-me {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  .about-me {
    &__avatar {
      width: 80%;
      height: 80%;
      padding: 2rem 0;
      margin: 0;
    }

    &__description {
      align-items: center;
      margin: 0 3rem 1rem;
    }
  }

  .navbar {
    &__logo {
      font-size: 24px;
    }

    &__links {
      display: none;
    }

    &__link {
      font-size: 14px;
      padding: 5px;
    }
  }

  .description {
    &__headline {
      font-size: 32px;
    }

    &__text {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .skills {
    &__list {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .projects {
    &__container {
      grid-template-columns: 1fr;
    }
  }

  .project {
    margin-bottom: 30px;

    &__prev {
      height: 17rem;
    }
  }

  .contact {
    &__container {
      flex-direction: column;
      align-items: start;
    }

    &__social-link {
      padding: 0;
    }
  }
}
